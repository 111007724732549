<script>
import PublicContentService from '../service/PublicContentService';

// logos plataformas:
import LogoAmazon from '../assets/img/logos/logo-amazon.png';
import LogoMagalu from '../assets/img/logos/logo-magalu.png';
import LogoShein from '../assets/img/logos/logo-shein.png';
import LogoShopee from '../assets/img/logos/logo-shopee.png';
import LogoMercadoLivre from '../assets/img/logos/logo-mercadolivre.png';

export default {
    data() {
        return {
            offerData: {},
            logos: {
                Amazon: LogoAmazon,
                Magalu: LogoMagalu,
                Shein: LogoShein,
                Shopee: LogoShopee,
                Mercadolivre: LogoMercadoLivre
            }
        }
    },
    publicContentService: null,

    created() {
        this.publicContentService = new PublicContentService();
    },

    methods: {
        timeAgo(dateString) {
            const currentDate = new Date(dateString);
            const now = new Date();
            const differenceInMillis = now - currentDate;

            const seconds = Math.floor(differenceInMillis / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            if (days > 1) {
                return `há ${days} dias`;
            } else if (days === 1) {
                return `há 1 dia`;
            } else if (hours > 1) {
                return `há cerca de ${hours} horas`;
            } else if (hours === 1) {
                return `há cerca de 1h`;
            } else if (minutes > 1) {
                return `há cerca de ${minutes} minutos`;
            } else if (minutes === 1) {
                return `há 1 minuto`;
            } else {
                return `agora mesmo`;
            }
        },
        async copyCouponCode(offer) {
            try {
                await navigator.clipboard.writeText(offer.code);

                offer.showCopy = false;
                offer.copySuccess = true;

                setTimeout(() => {
                    offer.copySuccess = false;
                    offer.showCopy = true;
                }, 3000);
            } catch (err) {
                console.error('Erro ao copiar o texto: ', err);
            }
        }
    },

    mounted() {

        this.publicContentService
            .getContent(this.$route.params.id)
            .then((data) => {
                if (data) {

                    this.offerData = data;
                    console.log(data)
                    // this.createdAt = new Intl.DateTimeFormat('default', { dateStyle: 'short' }).format(new Date(data.createdAt));
                    // this.updatedAt = new Intl.DateTimeFormat('default', { dateStyle: 'short' }).format(new Date(data.updatedAt));
                }
                else {
                    this.$router.push('/404')
                }
            });
    }
}
</script>

<template>
    <div class="flex justify-center items-center mt-8">

        <a
            :href="offerData.link"
            class="sm:max-w-xs lg:max-w-sm mx-auto bg-white flex flex-col gap-y-2 justify-between items-center pt-4 pb-6 px-4 lg:px-6 rounded-2xl shadow-md border border-slate-50 hover:border-slate-300 transition duration-300 ease cursor-pointer">

            <div class="pt-6 pb-4 shrink-0 flex justify-center items-center">
                <img :src="offerData.image" :alt="offerData.title" class="w-[85%] object-contain object-center rounded-3xl aspect-square" />
            </div>
            <h2 class="text-base tracking-tight font-normal text-slate-700 text-center mb-0">{{ offerData.title }}</h2>
            <div class="mt-2 flex flex-col justify-center items-center gap-y-1">
                <span class="block text-center text-sm text-slate-500 font-normal">A partir de:</span>
                <p class="text-[#5e4d37] text-xl tracking-tight font-bold mb-0">{{ (offerData.price / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                <div class="text-center text-sm text-slate-500 font-normal">
                    <span v-if="offerData.installments > 1">Em até {{ offerData.installments }}x sem juros</span>
                    <span v-else>À vista</span>
                </div>
            </div>

            <div class="w-full mt-2">
                <a :href="offerData.link" class="inline-flex w-full justify-center rounded-md border border-transparent bg-[#0bbf57] py-3 px-5 text-sm font-semibold text-white shadow-sm hover:bg-[#1b8746] hover:text-white focus:outline-none focus:ring-2 focus:ring-[#24b45d] focus:ring-offset-2 disabled:opacity-75 cursor-pointer">
                    Pegar promoção
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-5 h-5 ml-2 -mr-1">
                        <path fill-rule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clip-rule="evenodd"></path>
                        <path fill-rule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clip-rule="evenodd"></path>
                    </svg>
                </a>
            </div>

            <div v-if="offerData.code" @click="copyCouponCode(offerData)" class="w-full rounded-lg px-4 py-3.5 border-2 border-dashed border-slate-400 hover:border-teal-600 hover:shadow-md transition duration-300 ease my-2 flex items-center justify-between cursor-pointer">
                <div v-show="offerData.showCopy" class="flex justify-between items-center w-full gap-x-1">
                    <div class="coupon-code font-bold text-ellipsis overflow-hidden text-sm">
                        {{ offerData.code }}
                    </div>
                    <div class="flex items-center gap-x-1 text-sm text-slate-600 font-normal">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"></path>
                        </svg>
                        Copiar
                    </div>
                </div>
                <div v-if="offerData.copySuccess" class="success-copy text-sm font-normal flex h-[20px] items-center justify-center w-full text-slate-700">
                    Copiado com sucesso!
                </div>
            </div>

            <div class="w-full flex items-center justify-center gap-2 my-5" v-if="offerData.platform && offerData.platform !== ''">

                <div class="flex flex-col gap-0 items-center">
                    <p class="text-sm text-slate-600 mb-0">Oferta da loja:</p>
                    <p class="text-sm text-slate-700 mb-0 font-semibold">{{ offerData.platform }}</p>
                </div>

                <div class="shrink-0">
                    <img
                        v-if="logos[offerData.platform]"
                        :src="logos[offerData.platform]"
                        :alt="'Logo ' + offerData.platform"
                        class="border border-slate-300 rounded-full w-9 h-9 ratio-1x1" />
                </div>

            </div>

            <div class="w-full flex items-center justify-end gap-1 text-xs text-slate-500 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                {{ timeAgo(offerData.createdAt) }}
            </div>

            <div class="text-xs mt-3 mb-0 text-slate-400 text-center">
                O preço e disponibilidade do produto podem variar, pois as ofertas são por tempo limitado.
            </div>

        </a>
    </div>
</template>