import axios from "axios";

const StrapiBaseURL = 'https://strapi-185072-0.cloudclusters.net/api';

export default class PublicContentService {
    getContent(id) {
        const slugUrl = `${StrapiBaseURL}/product-offers?filters[id][$eq]=${id}&[isActive][$eq]=true&populate=*`;
        return axios.get(slugUrl).then((res) => {

            if (res.data.data.length > 0) {

                // console.log(res.data.data)

                return {
                    id: res.data.data[0].id,
                    createdAt: res.data.data[0].attributes.createdAt,
                    title: res.data.data[0].attributes.Title,
                    price: res.data.data[0].attributes.OfferPrice,
                    code: res.data.data[0].attributes.CouponCode,
                    link: res.data.data[0].attributes.AffiliateURL,
                    platform: res.data.data[0].attributes.FromPlatform,
                    isActive: res.data.data[0].attributes.IsOfferActive,
                    installments: res.data.data[0].attributes.InterestFreeInstallments,
                    image: StrapiBaseURL.replace('/api', '') + res.data.data[0].attributes.OfferImg.data[0].attributes.url,
                    copySuccess: false,
                    showCopy: true,
                }
            } else { return false }
        });
    }
}